<script lang="ts">
	export let type: 'confirm' | 'error' | 'neutral' | 'warning';
	import cn from 'classnames';
	import { twMerge } from 'tailwind-merge';
</script>

<div
	class={cn(
		twMerge(
			'w-full p-4 text-center text-black',
			type === 'confirm'
				? 'bg-green-zanah'
				: type === 'error'
					? 'bg-red'
					: type === 'warning'
						? 'bg-yellow'
						: 'bg-gray-alabaster',
			$$restProps.class
		)
	)}
>
	<slot />
</div>
